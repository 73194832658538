import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "src/components/common/layout"
import FadeInSection from "src/components/common/section/fadeInSection"
import FadeDirections from "src/components/common/section/fadeDirections"
import Intro from "src/components/intro/intro"
import Navbar from "src/components/navbar/navbar"

import Image from "src/components/common/image"
import SEO from "src/components/common/seo"

import {
  faTwitter,
  faLinkedin,
  faGithub,
  faHtml5,
  faJs,
  faReact,
} from "@fortawesome/free-brands-svg-icons"

import containerStyles from "./index.module.scss"
import ScrollLink from "src/components/navbar/scrollLink"
import LinkIcon from "src/components/common//linkIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const IndexPage = () => {
  const tabs = [
    { content: "TOP", href: "top" },
    { content: "About", href: "about" },
    { content: "Skills", href: "skills" },
    { content: "Work", href: "work" },
    { content: "Education", href: "education" },
    { content: "Contact", href: "contact" },
  ]

  const sectionColor = "rgba(204, 215, 223, 0.52)"

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          subTitle
        }
      }
    }
  `)
  
  return (
    <Layout>
      <SEO title="Home" />
      <Intro
        title={data.site.siteMetadata.title}
        subtitle={data.site.siteMetadata.subTitle}
      />

      <Navbar tabs={tabs} />
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <main>
        <FadeInSection id={tabs[1].href} direction={FadeDirections.top}>
          <div className="row center-xs start-md">
            <div className={"col-xs-12 col-md-3 "}>
              <div className={containerStyles.imageClip}>
                <div className={containerStyles.image}>
                  <Image path="John_Mondragon.jpg" />
                </div>
              </div>
            </div>
            <div className={"col-xs-12 col-md-9"}>
              <p>
                Hi! I am John a freelance web developer based in Nagoya, Japan.
                As a full-stack web developer I can help your team build modern
                custom web applications.
              </p>
              <p>
                See my
                <ScrollLink href="skills" tabIndex={-1}>
                  {" "}
                  skills{" "}
                </ScrollLink>
                bellow.
              </p>
              <p>
                Learn more about me:
                <LinkIcon
                  href="https://www.linkedin.com/in/mondragonjohn/"
                  title="LinkedIn"
                  icon={faLinkedin}
                  className={containerStyles.socialLink}
                />
                <LinkIcon
                  href="https://twitter.com/JohnMondragonC"
                  title="Twitter"
                  icon={faTwitter}
                  className={containerStyles.socialLink}
                />
                <LinkIcon
                  href="https://github.com/raskaman"
                  title="Github"
                  icon={faGithub}
                  className={containerStyles.socialLink}
                />
              </p>
            </div>
          </div>
        </FadeInSection>

        <FadeInSection
          id={tabs[2].href}
          color={sectionColor}
          direction={FadeDirections.top}
        >
          <div className="row">
            <div className={"col-xs-12 col-md-3 "}>
              <h2>My abilities</h2>
              <div className={containerStyles.icons + " row"}>
                <div className="col-xs-12 col-md-6">
                  <div className={containerStyles.icon}>
                    <FontAwesomeIcon icon={faJs} size="2x" />
                  </div>
                  <div className={containerStyles.icon}>
                    <Image path="icons8-sitecore-50.png" />
                  </div>
                  <div className={containerStyles.icon}>
                    <Image path="dot_net_1916026.png" />
                  </div>
                  <div className={containerStyles.icon}>
                    <Image path="icons8-c-sharp-logo-50.png" />
                  </div>
                  <div className={containerStyles.icon}>
                    <FontAwesomeIcon icon={faHtml5} size="2x" />
                  </div>
                  <div className={containerStyles.icon}>
                    <Image path="icons8-css-52.png" />
                  </div>
                  <div className={containerStyles.icon}>
                    <FontAwesomeIcon icon={faReact} size="2x" />
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-xs-12 col-md-9"}>
              <p>
                I can work cross-functionally on the full “stack” of technology
              </p>
              <div className="row">
                <div className={"col-xs-12 col-md-5"}>
                  <h3>Back End Development</h3>
                  <div className="row">
                    <div className={"col-xs-10"}>
                      <ul className={containerStyles.skillList}>
                        <li>Sitecore CMS</li>
                        <li>ASP.NET </li>
                        <li>C# language </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-7">
                  <h3>Front End Development</h3>
                  <div className="row">
                    <div className={"col-xs-12 col-md-6 col-lg-4"}>
                      <ul className={containerStyles.skillList}>
                        <li>JavaScript</li>
                        <li>HTML</li>
                        <li>CSS</li>
                      </ul>
                    </div>
                    <div className={"col-xs-12 col-md-6 col-lg-4"}>
                      <ul className={containerStyles.skillList}>
                        <li>Gatsby JS</li>
                      </ul>
                    </div>
                    <div className={"col-xs-12 col-md-6 col-lg-4"}>
                      <ul className={containerStyles.skillList}>
                        <li>React JS</li>
                        <li>Responsive Design</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-5">
                  <h3>Tools</h3>
                  <div className="row">
                    <div className={"col-xs-12 col-md-6"}>
                      <ul className={containerStyles.skillList}>
                        <li>GIT</li>
                        <li>Gulp</li>
                        <li>Grunt</li>
                        <li>SASS</li>
                        <li>LESS</li>
                      </ul>
                    </div>
                    <div className={"col-xs-12 col-md-6"}>
                      <ul className={containerStyles.skillList}>
                        <li>npm</li>
                        <li>Visual Studio</li>
                        <li>VS Code</li>
                        <li>TDS</li>
                        <li>Azure</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-7">
                  <h3>Other</h3>
                  <div className="row">
                    <div className={"col-xs-12"}>
                      <ul className={containerStyles.skillList}>
                        <li>Onion Architecture / Clean Architecture</li>
                        <li>Unit Test</li>
                        <li>Automated Test</li>
                        <li>DevOps</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection id={tabs[3].href} direction={FadeDirections.top}>
          <div className="row">
            <div className={"col-xs-12 col-md-3"}>
              <h2>My Experience</h2>
            </div>
            <div className={"col-xs-12 col-md-9"}>
              <h2>Full-Stack (C#, React Js) Web Developer (Sitecore)</h2>
              <h3>Mercury NZ Limited</h3>
              <p>
              Providing freelance development services to clients that utilize the Sitecore CMS, 
              building custom Sitecore functionality that integrates with multiple systems via REST APIs, 
              leading the front-end and back-end development of our Scrum team. Also, mentoring junior Sitecore developers, 
              migrating legacy web forms to MCV, unit testing, and test automation.
              </p>

              <div>
              <h3>Project: React JS Build of main sales web application</h3>
              <p>As the principal developer led the re-build of the sales app for a leading energy company in New Zealand</p>
              <a href="https://www.mercury.co.nz/join" target="_blank">
              <div className={containerStyles.imageMer}>
                <Image path="mercury1.jpg" />
              </div>
                
              </a>
              <br />
              <div />

              <h2>Senior Sitecore Developer</h2>
              <h3>Datacom</h3>
              <p>
              Providing Sitecore development services for the company clients, implemented multiple requirements which required the full-stack developer skill set.
              </p>

              
              </div>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection
          id={tabs[4].href}
          direction={FadeDirections.top}
          color={sectionColor}
        >
          <div className="row">
            <div className={"col-xs-12 col-md-3"}>
              <h2>Education</h2>
            </div>
            <div className={"col-xs-12 col-md-9"}>
              
              <h2>Indiana University-Purdue University at Indianapolis</h2>
              <h3>Bachelor of Science (B.S.), Computer Information Technology</h3>
              <p>
              Graduated in May 2010. Gained the skills to create and maintain software applications and complex web sites, including static and dynamic web sites; able to develop applications in C# for the .NET framework.
              </p>
              
              <h2>Ivy Tech Community College</h2>
              <h3>Associate of Science (A.S.), Computer Information Technology</h3>
              <p>
              Able to manage a database such as Microsoft Access and SQL. Also, write and maintain computer programs using Visual Basic, C# and Java programming languages.
              </p>
            </div>
          </div>
        </FadeInSection>
        <FadeInSection id={tabs[5].href} direction={FadeDirections.right}>
          <div className="row">
            <div className={"col-xs-12 col-md-3"}>
              <h2>Reach out to me</h2>
            </div>
            <div className={"col-xs-12 col-md-9"}>
              <p>
                I work remotely either from home or from a coworking space in
                downtown Nagoya
                <br />
                <a href="mailto:john.mondragon.c@gmail.com">
                  john.mondragon.c@gmail.com
                </a>
                <br />
              </p>
              <h3>+81 080-4548-3432</h3>
            </div>
          </div>
        </FadeInSection>
      </main>
    </Layout>
  )
}

export default IndexPage
